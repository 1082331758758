import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import "../css/article.css"

function Cookies() {

  return (
    <Layout>

      <Seo title="Politica de privacidade e seguranca" description="Conheça a política de segurança e privacidade do QuantoSobra. Como cuidamos dos seus dados e dos nossos servidores para garantir maior segurança." />

      <div class="postContent mt-32 mb-10 mx-auto max-w-xs md:max-w-screen-md">
        <p><strong>POLÍTICA DE PRIVACIDADE</strong></p>
        <p>Nós da AWISE SOLUCOES TECNOLOGICAS LTDA., inscrita no CNPJ sob n.° 13.130.316/0001-40, com sede na R. Gen. Osório, 89 – Universitário, Santa Cruz do Sul – RS, 96810-002 respeitamos a sua privacidade e seus dados pessoais. Estamos comprometidos em cumprir com o disposto na Lei Geral de Proteção de Dados (Lei n.° 13.709/2018) e legislações internacionais aplicadas caso a caso.</p>
        <p>Informamos que nossa atuação é, em regra, como operadora de dados, pois fornecemos serviços aos terceiros/empresas. Excepcionalmente, poderemos atuar como controladora de dados, tendo contato diretamente com o titular.&nbsp;</p>
        <p><strong>DADOS PESSOAIS COLETADOS E FINALIDADES</strong></p>
        <p>No momento em que você acessa nosso site, entra em contato conosco por algum canal de comunicação, realiza cadastros ou utiliza alguns dos nossos serviços, são coletados diferentes dados pessoais para finalidades específicas.</p>
        <p>Os dados poderão ser justificados em diferentes bases legais, a depender da finalidade e do dado pessoal relacionado, como consentimento, execução de contrato e cumprimento de obrigação legal e regulatória.</p>
        <p>Preparamos uma relação para você visualizar para quais finalidades solicitamos os seus dados pessoais e quais os dados são preenchidos/ encaminhados / informados por você, conforme segue:</p>
        <ol>
          <li><strong> Atendimento a solicitação de testes do sistema:</strong> Nome, e-mail e telefone;</li>
          <li><strong> Atendimento para negociação de planos, descontos e informações gerais:</strong> Nome, e-mail e telefone.</li>
          <li><strong> Auxílio nos testes do sistema:</strong> Nome, telefone, e-mail, nome da empresa, CNPJ.</li>
          <li><strong> Aquisição de software pelo cliente:</strong> Nome, CNPJ, e-mail, formas de contato, razão social, telefone e endereço;</li>
          <li><strong> Emissão de nota fiscal:</strong> Nome/ Razão social, CNPJ, e-mail, endereço completo, telefone,&nbsp; inscrição estadual, inscrição municipal;</li>
          <li><strong> Aquisição de material gratuito do site ou teste grátis do sistema:</strong> Nome, e-mail, se possui empresa, setor da empresa e número de empregados;</li>
          <li><strong> Atendimento da solicitação de cancelamento</strong>: Nome empresa, CNPJ e e-mail;</li>
          <li><strong> Preenchimento de formulário para concorrer a alguma vaga de trabalho:</strong> Nome, e-mail, telefone, e arquivo de currículo.</li>
          <li><strong> Dados de crédito: </strong>a forma de pagamento será escolhida por você.<strong></strong>
            <ol>
              <li>Cartão de crédito: nós não armazenamos qualquer informação do seu cartão de crédito, como número de cartão, nome, data de validade, código. Todos os dados permanecem armazenados diretamente nos servidores da operadora de cartão IUGU. Para mais informações, acesse a política de privacidade da IUGU: https://www.iugu.com/juridico/politica-de-privacidade/</li>
              <li>Boleto: E-mail, razão social, nome fantasia, endereço completo com CEP, CNPJ/CPF e telefone;</li>
              <li>PIX: E-mail, razão social, nome fantasia, endereço completo com CEP, CNPJ/CPF e telefone;</li>
            </ol>
          </li>
        </ol>
        <p>Você poderá revisar seu cadastro, fazer alterações nos dados ou nas configurações da sua conta, bem como encerrá-la a qualquer momento. O usuário possui o direito de solicitar a exclusão de seus dados pessoais, contudo informamos que, para o cumprimento de obrigação legal e defesa em processo judicial, arbitral ou administrativo poderemos reter as informações necessárias.</p>
        <p>Ainda, para fornecer a você um serviço eficiente e seguro, poderemos utilizar ferramentas que combinam informações (como ActiveCampaign, Buzzled, Hotjar, Google Analytics, Yandex Metrica) para aprimorar os nossos serviços. Garantimos que as suas informações não são publicadas, fornecidas nem comercializadas com terceiros.</p>
        <p>Mais informações sobre os Cookies, revogaço, consentimento etc devem ser acessados a partir do Link: <a href="https://blog.quantosobra.com.br/declaracao-de-uso-de-cookies">https://blog.quantosobra.com.br/declaracao-de-uso-de-cookies</a></p>
        <p><strong>ARMAZENAMENTO DOS DADOS</strong></p>
        <p>Os seus dados pessoais e outras informações serão armazenados nos computadores, servidores e backups, localizados no Brasil e Estados Unidos.</p>
        <p>Manteremos os seus dados arquivados pelo prazo em que forem utilizados, para o cumprimento de obrigação legal ou regulatória, bem como para o exercício regular de direito. Assim, havendo autorização legal, os seus dados permanecerão arquivados conosco.</p>
        <p>Os dados pessoais fornecidos pelo titular (usuário) ficaram retidos durante o período de cumprimento de sua finalidade.&nbsp;</p>
        <p>E quando você, nosso cliente, adicionar informações no nosso sistema dos seus clientes, realizaremos apenas o armazenamento das informações em nossos servidores e backups, podendo alterar os cadastros quando ocorrer alguma solicitação do titular de dados.</p>
        <p>Por isso, excluiremos as informações de cadastros quando solicitado por você, nosso cliente ou a partir de solicitações dos titulares de dados, de acordo com os procedimentos de atendimento aos direitos dos titulares de dados.&nbsp;</p>
        <p><strong>SEGURANÇA DOS DADOS</strong></p>
        <p>Utilizamos certificado SSL para que toda a comunicação entre o usuário (você) e o nosso servidor seja criptografada por meio de uma chave de segurança de 256 bits. Estamos sob cuidados de profissionais especializados 24h por dia com políticas de segurança como firewalls e criptografias. Nosso sistema conta com backups de todos os dados diariamente! Não se preocupe mais em manter aqueles pendrives em casa. Empregamos toda segurança possível no armazenamento dos dados e backups.</p>
        <p>Recomendamos que você utilize medidas de segurança essenciais para o uso da internet, como antivírus, senhas individuais, controle de acesso, etc. Além de treinar sua equipe sobre a privacidade e necessidade de proteção dos dados pessoais dos seus clientes.</p>
        <p>A nossa equipe está em constante treinamento de conscientização sobre a importância da privacidade e da proteção dos dados pessoais, possuindo ainda documentos internos de sigilo para assegurar a inviolabilidade dos dados dos nossos clientes.</p>
        <p><strong>INFORMAÇÕES SOBRE O COMPARTILHAMENTO</strong></p>
        <p>As suas informações pessoais poderão ser compartilhadas nas seguintes hipóteses:</p>
        <ol>
          <li> Internamente para o atendimento de suas solicitações;&nbsp;</li>
          <li> Com fornecedores que auxiliam a nossa empresa a oferecer um serviço de alto valor agregado (marketing, empresas de cobrança, de tecnologia da informação, jurídico). Nossos fornecedores são obrigados por contrato a manter confidencialidade das informações recebidas;</li>
          <li> Com autoridades legais, governamentais e outros terceiros se houver intimação, citação, notificação extrajudicial ou judicial, decisão judicial e/ou outro procedimento legal;</li>
          <li> Instituições financeiras para o pagamento dos serviços adquiridos;</li>
          <li> Com escritório de contabilidade;</li>
          <li> Com o Governo para o cumprimento de obrigação legal ou regulatória;</li>
          <li> Quando você nos der um consentimento específico para o compartilhamento;</li>
        </ol>
        <p>&nbsp; Queremos deixar claro que poderemos transferir internacionalmente os seus dados, quando realizamos o armazenamento em servidores localizados nos Estados Unidos.</p>
        <p>Não iremos, em nenhuma hipótese, comercializar suas informações pessoais com terceiros, salvo se expressamente autorizado por você ou no caso de solicitação legal.</p>
        <p><strong>INFORMAÇÃO SOBRE OS DADOS DE CRIANÇAS E ADOLESCENTES</strong></p>
        <p>Não realizamos o tratamento de dados pessoais (seja coleta, armazenamento, compartilhamento, etc.) dos dados pessoais de crianças e adolescentes, uma vez que nossos serviços não são destinados a esses grupos.</p>
        <p>No entanto, caso haja divulgação de vaga de trabalho na nossa equipe para jovem aprendiz, iremos coletar o consentimento específico de um dos pais ou responsável legal, conforme disposto na Lei Geral de Proteção de Dados.</p>
        <p><strong>ATENDIMENTO AOS DIREITOS DO TITULAR</strong></p>
        <p>De acordo com as legislações e regulamentos de proteção de dados, você possui diversos direitos. O exercício desses direitos podem estar sujeitos a algumas exceções, como exercício regular de direitos, sigilo profissional, comercial e industrial, prazos legais para cumprimento de obrigações, interesse público entre outros.</p>
        <p>Isso significa que em alguns casos os seus dados são processados para que possamos oferecer nossos serviços a você. Assim, não podendo ser atendida a sua solicitação, iremos informar no menor prazo possível, indicando os motivos.</p>
        <p>Conforme estabelecido na Lei Geral de Proteção de Dados (Lei 13.709/2018), você possui o direito de:</p>
        <ol>
          <li>Receber a confirmação do tratamento;</li>
          <li>Acesso aos dados;</li>
          <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
          <li>Requisitar a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados ilegalmente;</li>
          <li>Requisitar a portabilidade de seus dados pessoais a outro fornecedor de serviço ou produto, observado o segredo industrial e comercial;</li>
          <li>Eliminação dos dados pessoais tratados com o consentimento do titular, exceto os casos de sua manutenção (art. 16 da Lei);</li>
          <li>Requisitar o recebimento de informações sobre as entidades privadas e públicas com as quais o controlador compartilhou os seus dados;</li>
          <li>Se opor ao tratamento, quando este for ilegal;</li>
          <li>Informação sobre a possibilidade de não fornecer o consentimento e sobre as consequências da negativa;</li>
          <li>Revogar o consentimento.</li>
        </ol>
        <p>Estando o tratamento de seus dados pessoais sujeito ao Regulamento Geral de Proteção de Dados da UE (“GDPR”) e suas informações pessoais forem processadas com base em interesses legítimos, você poderá se opor ao processamento, baseado em uma situação específica. Você também tem o direito de solicitar a exclusão ou restrição de seus dados pessoais, bem como solicitar a portabilidade de seus dados pessoais.</p>
        <p>Para o atendimento de suas solicitações, favor encaminhá-las pelo E-mail: dpo@quantosobra.com.br</p>
        <p><strong>INFORMAÇÕES DO ENCARREGADO</strong></p>
        <p>Nome: Maríllia dos Santos Dias</p>
        <p>E-mail: dpo@quantosobra.com.br</p>
        <p>Quaisquer solicitações, informações e esclarecimentos poderão ser encaminhados diretamente pelo e-mail indicado.</p>
        <p><strong>INFORMAÇÃO SOBRE A POSSIBILIDADE DE ALTERAÇÃO DA POLÍTICA</strong></p>
        <p>Nós poderemos atualizar esta Política de Privacidade periodicamente com o objetivo de refletir a Governança de Dados da AWISE. Assim, todas as nossas políticas terão data de atualização.&nbsp;</p>
        <p>Quando realizarmos alguma mudança substancial, faremos um aviso no site/sistema para que você tenha conhecimento.</p>
        <p><strong>CESSÃO DE DIREITOS</strong></p>
        <p>Independentemente de qualquer aviso ou notificação ao cliente, poderemos ceder os direitos oriundos desta Política de Privacidade, no todo ou em parte, a outras empresas, oriundas de transações societárias, como fusão, aquisição, ou joint-venture, a qualquer momento, desde que observada a legislação vigente, regulamentos e normas aplicáveis a esta Política de Privacidade.</p>
        <p><strong>CONSIDERAÇÕES FINAIS</strong></p>
        <p>A presente Política de Privacidade é parte integrante e inseparável do Termo e Condições de Uso e está vinculada a este. As cláusulas e condições que não estão previstas por esta Política de Privacidade, utilizarão, subsidiariamente, os Termos e Condições de Uso.</p>
      </div>

    </Layout>
  )
}

export default Cookies